import React from "react"

import Layout from "../../layouts/En"

import SEO from "../../components/SEO"
import Card from "../../components/Card"

import Brackets from "../../icons/brackets.svg"
import Hat from "../../icons/hat.svg"
import Tools from "../../icons/tools.svg"

const ServicesPage = () => (
  <Layout title="What We Do">
    <SEO keywords={[`what`, `we`, `do`]} title="Services" />

    <section className="flex justify-center text-center mb-12">
      <div className="w-full sm:w-3/4 md:w-2/3">
        <p className="mb-8">
          In addition to software development and software integration, our
          services include design, implementation and maintenance of IT
          services. The basis of our work is functionality of services and
          products, realistic price-performance ratio and facilitating
          customers’ independence. Our ongoing mission is to develop and
          maintain an adaptive operating environment tailored to our customers’
          needs.
        </p>
      </div>
    </section>

    <section className="flex flex-wrap justify-center text-center mb-12">
      <div className="w-full md:w-1/2 pb-8">
        <Card className="mx-auto md:mx-4 h-full">
          <Hat className="mx-auto mb-8 h-24 text-orange" />
          <h3 className="mb-4">Integration</h3>
          <p>
            In addition to software integration we also do operating environment
            integration as well as integration of small and medium-sized
            systems. Our long experience guarantees the implementation of even
            the most challenging integrations.
          </p>
        </Card>
      </div>

      <div className="w-full md:w-1/2 pb-8">
        <Card className="mx-auto md:mx-4 h-full">
          <Tools className="mx-auto mb-8 h-24 text-orange" />
          <h3 className="mb-4">IT-support</h3>
          <p>
            We offer consultation services for purchasing various systems, such
            as, computers, servers, testers, diagnostic equipment and software
            applications. We handle their order, installation, maintenance,
            control and management. We also provide the necessary training and
            support. Our services also include securing and restoring
            information.
          </p>
        </Card>
      </div>

      <div className="w-full md:w-1/2 pb-8">
        <Card className="mx-auto md:mx-4 h-full">
          <Brackets className="mx-auto mb-8 h-24 text-orange" />
          <h3 className="mb-4">Websites</h3>
          <p>
            We create websites, -stores or -applications tailored to your needs
            with strong professionalism and modern technologies. If necessary,
            we can also take care of hosting and maintenance.
          </p>
        </Card>
      </div>
    </section>
  </Layout>
)

export default ServicesPage
